<template>
  <div class="boxFilter mt-4" :class="{ traditional: isMobile }">
    <div class="filterLeft d-flex align-items-end justify-content-between">
      <b-form-group
        class="mr-3 pr-lg-1"
        id="group-dob"
        label-for="input-dob"
        :style="{ width: isMobile ? '50%' : '190px' }"
      >
        <template v-slot:label><p class="font-weight-600">Kết quả xổ số</p></template>
        <div class="justify-content-center timeDialFilter relative">
          <b-form-select v-model="currentGameId" :options="games" class="customInput"></b-form-select>
        </div>
      </b-form-group>
      <b-form-group class="mr-md-3 pr-lg-1" id="group-dob" label-for="input-dob">
        <template v-slot:label><p class="font-weight-600">Kỳ quay ngày</p></template>
        <div class="justify-content-start timeDialFilter relative">
          <UIDateTimePickerCommon
            :date_of_birth="dataTimeDialItem"
            :title="'Kỳ quay ngày'"
            @handlerInputCalendar="handlerInputCalendar"
            :maxDateDesktop="new CustomDate()"
            :maxDateMobile="maxDateMobile"
            :minYear="2015"
            :minDateDesktop="new CustomDate('2015')"
          ></UIDateTimePickerCommon>
        </div>
      </b-form-group>
      <div class="d-flex flex-grow-1 justify-content-between btnHolder" style="width: 100%" v-if="!isMobile">
        <button
          class="btn customInput"
          v-for="(day, index) in days"
          :key="index"
          @click.prevent="setDay(index)"
          :class="{ active: currentDayIndex === index }"
        >
          {{ day }}
        </button>
      </div>
    </div>
    <div
      class="d-flex flex-grow-1 justify-content-between btnHolder mt-3 overflow-auto"
      style="width: 100%"
      v-if="isMobile"
    >
      <button
        class="btn customInput mr-2"
        v-for="(day, index) in days"
        :key="index"
        @click.prevent="setDay(index)"
        :class="{ active: currentDayIndex === index }"
      >
        {{ day }}
      </button>
    </div>
  </div>
</template>
<script>
import UIDateTimePickerCommon from '@/components/UI/DateTimePicker/UIDateTimePickerCommon';
import { isMobile } from 'mobile-device-detect';

export default {
  components: {
    UIDateTimePickerCommon
  },
  data() {
    return {
      dataTimeDialItem: this.dataTimeDial,
      currentGameId: 8,
      games: [{ value: 8, text: 'Miền Nam' }],
      // CN= 0, T2=1
      days: ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7', 'CN'],
      currentDayIndex: null,
      dayItem: this.day
    };
  },
  props: ['dataTimeDial', 'fromTime', 'toTime', 'getByUser', 'disabledDates', 'gameId'],
  computed: {
    isMobile() {
      return isMobile;
    },
    maxDateMobile() {
      var d = new CustomDate(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    }
  },
  watch: {
    dataTimeDial: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.dataTimeDialItem = newVal;
      }
    },
    day: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.dayItem = newVal;
        this.currentDayIndex = newVal === 0 ? 6 : parseInt(newVal) - 1;
      }
    }
  },
  mounted() {},
  methods: {
    handlerInputCalendar(newVal) {
      this.dataTimeDialItem = newVal;
      this.handlerFilter();
    },
    handlerLink(gameId) {
      let router = this.getRouteStatisticalByGameId(gameId);
      this.$router.push(router);
    },
    handlerFilter() {
      let rs = {
        dataTimeDial: this.dataTimeDialItem,
        day: this.dayItem
      };
      this.$bus.$emit('updateFilterKeno', rs);
    },
    setDay(index) {
      if (this.currentDayIndex === index) {
        this.currentDayIndex = null;
        this.dayItem = null;
      } else {
        this.currentDayIndex = index;
        if (index === 6) {
          this.dayItem = 0;
        } else {
          this.dayItem = index + 1;
        }
      }
      this.handlerFilter();
    }
  }
};
</script>
<style lang="scss" scoped>
.boxFilter {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem 1.25rem 1.5rem 1.25rem;
  margin-bottom: 20px;
  .form-group {
    margin-bottom: 0;
    // width: 147px;
  }
  .filterDateDial {
    .form-control {
      width: 112px;
    }
  }
  .boxFilterBtn {
    position: relative;
    display: flex;
    .boxFilterBtnChild {
      position: absolute;
      bottom: 0;
      display: flex;
    }
  }
  &.traditional {
    padding: 12px;
  }
}
.btnHolder {
  height: 40px;
}
.customInput {
  background-color: #f7f7f7;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 3px;
  &.btn {
    min-width: 99px;
    &.active {
      background-color: #00509d;
      color: #fff;
    }
  }
}
</style>
