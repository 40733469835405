<template>
  <div>
    <template>
      <FilterTraditionalGame :dataTimeDial="dataTimeDial" />
    </template>

    <component
      :is="component"
      :pagination="pagination"
      :listResult="customListResult"
      :totalPage="totalPage"
      :rewards="rewards"
      @updatePagination="updatePagination"
      @getGameResult="getGameResult"
      @onResize="onResize"
      :isLoadEnd="isLoadEnd"
      :isLoading="isLoading"
      :isLoadingDesktop="isLoadingDesktop"
      :gameId="gameId"
      :namePage="namePage"
      :getByUser="getByUser"
    ></component>
  </div>
</template>
<script>
import { getEntityInfoResult } from '@/utils/functions';
import GameAppService from '@/api/game';
import FilterTraditionalGame from '@/components/TraditionalGame/FilterTraditionalGame';
import { isMobile } from 'mobile-device-detect';
export default {
  components: {
    TraditionalResult: () => import('./TraditionalResult'),
    FilterTraditionalGame
  },
  data() {
    return {
      component: null,
      totalPage: 0,
      dataTimeDial: '',
      fromTime: '',
      toTime: '',
      day: '',
      rememberTicket: false,
      getByUser: false,
      timerWaiting: null,
      namePage: '',
      gameId: 0,
      bottom: 50,
      dataAgain: {},
      dataAgainMobile: {},
      isLoading: false,
      isLoadingDesktop: false,
      disabledDates: {},
      pagination: {
        currentPage: 0,
        pageSize: 12
      },
      listResult: [],
      rewards: [],
      isLoadEnd: false,
      lastWidth: undefined,
      isMobile: isMobile,
      periodNo: null
    };
  },
  computed: {
    getterResultGame() {
      return this.$store.getters.getterResultGameUrl;
    },
    listGameById() {
      return this.$store.getters.getterListGame;
    },
    customListResult() {
      if (this.listGameById?.[this.gameId]?.handler === 'max3dpro') {
        // Thêm giải phụ đặc biệt cho game max3dpro
        return this.listResult.map((item) => {
          const currentListResult = [...item.lottery_result];
          const firstResult = currentListResult.shift();
          const reverseFirstResult = [...firstResult].reverse();

          currentListResult.unshift(reverseFirstResult);
          currentListResult.unshift(firstResult);

          item.lottery_result = currentListResult;
          return item;
        });
      } else {
        return this.listResult;
      }
    },
    isTraditional() {
      return this.gameId === this.$store.getters.getterGameId['xsmn'];
    },
    gameService() {
      return this.isTraditional ? GameAppService.getTraditionalGameResult : GameAppService.getGameResult;
    },
    pageSize() {
      return this.isTraditional ? 7 : 12;
    }
  },
  created() {
    this.lastWidth = window.innerWidth;
    this.handlerCallFirstPage();
    this.$bus.$off('updateFilterKeno', this.handlerFilterResult());
    this.$bus.$on('updateFilterKeno', ($event) => {
      this.handlerFilterResult($event);
    });

    if (this.$route.query) {
      if (this.$route.query.period_no) {
        this.fromTime = this.$route.query.period_no;
        this.toTime = this.$route.query.period_no;
        this.periodNo = this.$route.query.period_no;
      }
      if (this.$route.query.date) {
        this.dataTimeDial = new CustomDate(this.$route.query.date);
      }
      if (this.$route.query.day) {
        this.day = this.$route.query.day;
      }
    }
  },
  methods: {
    onResize() {
      if (this.lastWidth === window.innerWidth) {
        return;
      }
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      this.listResult = [];
      this.dataAgain = {};
      this.dataAgainMobile = {};
      this.isLoadEnd = false;
      if (isMobile) {
        this.pagination = { currentPage: 0, pageSize: this.pageSize };
        this.getGameResult();
      } else {
        this.updatePagination({ currentPage: 1, pageSize: this.pageSize });
      }
    },
    handlerFilterResult(e) {
      if (JSON.stringify(e)) {
        this.fromTime = e.from;
        this.toTime = e.to;
        this.dataTimeDial = e.dataTimeDial;
        this.getByUser = e.getByUser;
        this.day = e.day;
        this.isLoadingDesktop = true;
        const isMobile = window.innerWidth <= this.getMobileThresshold();
        if (isMobile) {
          this.pagination = { currentPage: 0, pageSize: this.pageSize };
          this.isLoadEnd = false;
          this.getGameResult();
        } else {
          this.updatePagination({ currentPage: 1, pageSize: this.pageSize });
          this.getGameResultPaging(1);
        }
      } else {
        this.fromTime = null;
        this.toTime = null;
        this.dataTimeDial = null;
        this.getByUser = false;
        this.day = null;
      }
    },
    updatePagination(obj) {
      this.pagination = obj;
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (!isMobile) {
        this.getGameResultPaging();
      }
    },

    async handlerCallFirstPage() {
      this.isLoading = true;

      let entity = await getEntityInfoResult(this.$route, this.getterResultGame);
      if (entity) {
        this.gameId = entity.entityId;
        this.component = entity.entityName;
        this.namePage = this.getterResultGame.filter((p) => p.id == this.gameId)[0].name || '';
        this.disabledDates = entity.entityWeekday;
        this.dataTimeDial = this.dataTimeDial || entity.entityTimerPicker;
        this.$emit('updateGameId', this.gameId);
      } else {
        return this.$router.push({ name: 'notFound' });
      }
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (isMobile) {
        this.getGameResult();
      } else {
        this.getGameResultPaging(1);
      }
    },

    async getGameResultPaging(exactPage) {
      let data = {
        from: this.fromTime,
        to: this.toTime,
        limit: this.pageSize,
        offset: exactPage ? exactPage : this.pagination.currentPage,
        date: this.dataTimeDial,
        getByUser: this.getByUser,
        gameId: this.gameId,
        day: this.day,
        periodNo: this.periodNo
      };
      if (JSON.stringify(data) == JSON.stringify(this.dataAgain)) {
        this.isLoadingDesktop = false;
        return;
      }

      if (this.timerWaiting) {
        clearTimeout(this.timerWaiting);
      }
      this.timerWaiting = setTimeout(() => {
        this.gameService(this.gameId, { ...data, gameId: undefined })
          .then((resp) => {
            this.listResult = resp.data.data.lottery_result || resp.data.data.periods;
            this.rewards = resp.data.data.reward_data || [];
            this.totalPage = resp.data.data.total;
            if (exactPage) {
              if (
                (resp.data.data.lottery_result || resp.data.data.periods) &&
                (resp.data.data.lottery_result || resp.data.data.periods.length) + this.pageSize * exactPage >=
                  resp.data.data.total
              ) {
                this.isLoadEnd = true;
              } else {
                this.isLoadEnd = false;
              }
            } else {
              if (
                (resp.data.data.lottery_result || resp.data.data.periods) &&
                (resp.data.data.lottery_result?.length || resp.data.data.periods?.length) +
                  this.pageSize * (this.pagination.currentPage + 1) >=
                  resp.data.data.total
              ) {
                this.isLoadEnd = true;
              } else {
                this.isLoadEnd = false;
              }
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.isLoading = false;
            this.isLoadingDesktop = false;
            this.dataAgain = data;
            if (exactPage) this.pagination.currentPage = exactPage;
          });
      }, 300);
    },

    getGameResult() {
      if (this.isLoadEnd) {
        this.isLoading = false;
        this.isLoadingDesktop = false;
        return;
      }
      let data = {
        from: this.fromTime,
        to: this.toTime,
        limit: this.pageSize,
        offset: this.pagination.currentPage + 1,
        date: this.dataTimeDial,
        getByUser: this.getByUser,
        gameId: this.gameId,
        day: this.day,
        periodNo: this.periodNo
      };
      // alert(JSON.stringify(this.dataAgainMobile));
      // if (JSON.stringify(data) == JSON.stringify(this.dataAgainMobile)) {
      //   // alert('teste');
      //   this.isLoading = false;
      //   this.isLoadEnd = true;
      //   this.isLoadingDesktop = false;
      //   return;
      // }
      // alert('2');
      this.isLoading = true;
      clearTimeout(this.timerWaiting);
      this.timerWaiting = setTimeout(() => {
        this.gameService(this.gameId, { ...data, gameId: undefined })
          .then((resp) => {
            if (resp.data.data.total <= data.limit) {
              if (data.offset === 1) {
                this.listResult = resp.data.data.lottery_result || resp.data.data.periods;
                this.rewards = resp.data.data.reward_data || [];
                this.totalPage = resp.data.data.total;
              }
              this.isLoadEnd = true;
              return;
            }
            if (resp.data.data.periods && resp.data.data.periods.length > 0) {
              if (data.offset === 1) {
                this.listResult = resp.data.data.lottery_result || resp.data.data.periods;
                this.rewards = resp.data.data.reward_data || [];
                this.totalPage = resp.data.data.total;
                return;
              }

              this.listResult = this.listResult.concat(resp.data.data.lottery_result || resp.data.data.periods);
              this.rewards = resp.data.data.reward_data || [];
              this.totalPage = resp.data.data.total;
            } else {
              this.isLoadEnd = true;
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.isLoading = false;
            this.isLoadingDesktop = false;
            // this.dataAgainMobile = { ...data };
            this.pagination.currentPage++;
          });
      }, 1000);
    }
  }
};
</script>
